const defaultState = {
	loading:true,
	data: null,
	firstLoad:false,
	mainLoader:true,
};

export const manufacturersInitialState = (() => {
	
	return defaultState;
})();

export const manufacturersReducer = (state = manufacturersInitialState, action) => {
	let companies = state;
	switch (action.type) {
		
		case "MANUFACTURERS_LOADED":

			
			companies = {
				...state,
				mainLoader:false,
				loading: false,
				data: action.payload.data,
			};
			break;

		case "MANUFACTURERS_LOADING":
			companies = {
				...state,
				
				loading: true,
				firstLoad:true
				
			};
			break;

		case "MANUFACTURERS_FAIL":
			
			companies = {
				...state,
				
				loading: true,
				data: null
			};
			break;
		

		default:
			companies = state;
		break;
    }
    return companies;
};