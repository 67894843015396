const defaultState = {
	loading:false,
	items: null,
	adding:null
	
};

export const basketInitialState = (() => {
	
	return defaultState;
})();

export const basketReducer = (state = basketInitialState, action) => {
	let basket = state;
	switch (action.type) {
		case "BASKET_ADDING":
		
			basket = {
				...state,
				
				
				adding:action.file,
				
			};

			


		break;
		case "BASKET_LOADING":
		
		basket = {
			...state,
			
			loading: true,
			items:null,
			
		};


		break;
		case "BASKET_LOADED":

		

			basket = {
				...state,
				
				loading: false,
				items:action.payload.data,
				adding:null
			};

			
		break;

		default:
			basket = state;
		break;
    }
    return basket;
};