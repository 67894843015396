import React,{useState,useRef} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import editlogo from '../logos/edit.svg';
import { apiClient } from "../apiCall";
export const UploadFileDefaultState = {


    files:[],
    data:[],
    uploading:false
};

export default function UploadFile(props) {
	const { state, dispatch } = useStore();

  const [UploadFileState, setUploadFileState] = useState({
		...UploadFileDefaultState
		
    });


    const apiCall = apiClient(state, dispatch);

    const inputRef = useRef(null);
    const handleUpload = () => {
        inputRef.current?.click();
      };
      const onFileChange=(e)=>{

        var selected=[];
        var files=[...UploadFileState.files];
        for(var i=0;i<e.target.files.length;i++){
         

            selected.push({name:e.target.files[i].name});
        
            files.push(e.target.files[i]);
       
        }
        console.log(e.target.files);
        setUploadFileState({...UploadFileState, files:files});




      };
      const removeFile=(index)=>{

        var files=[...UploadFileState.files];
          files.splice(index, 1); 
          setUploadFileState({...UploadFileState, files:[...files]});

      };
      const files=UploadFileState.files.map((file,index) =>
        
            
        <div>{file.name} <span onClick={()=>removeFile(index)}>Poista</span></div>
   
      );
      const uploadPictures=()=>{

        setUploadFileState({...UploadFileState, loading:true});

        const formData = new FormData();
        for(var i=0;i<UploadFileState.files.length;i++){

          formData.append(
            "file["+i+"]",
            UploadFileState.files[i]
          );
           

          apiCall.UPLOAD(props.url, formData,function(status,err_msgs=null){
            
            alert("SAIN "+status);
            if(status==400){


            }

          });

        };

      };


      return (
        <div className="m-3">
          <label className="mx-3">Choose file: </label>
          <input ref={inputRef} onChange={onFileChange} className="d-none" type="file" multiple accept="image/*"/>
          <button onClick={handleUpload} className="btn btn-outline-primary">
            +
          </button>

        {files}
        {UploadFileState.files.length>0&&<button onClick={(e)=>uploadPictures()}>Lataa kuvat</button>}
        </div>
      );
}