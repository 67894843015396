import {React,useState} from "react";
import logo from '../logos/duell.svg';
import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link ,NavLink} from "react-router-dom";
import Basket from "./Basket";
import { useLocation } from 'react-router-dom';

export const HeaderStateDefaultState = {
	
    term:null
    
  };

  export const QueryStateDefaultState = {
	
    files:null,
    term:null
    
  };

 
export default function HeaderMenu(props) {
	const { state, dispatch } = useStore();

	const { user } = state;

    const location = useLocation();  


	const { categories,settings } = state;
    return <header><h1>{props.title}</h1><div className="buttons">{props.buttons}</div>
      <div class="langs"></div>
      </header>
}