import {React,useState,useEffect}  from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link,useHistory } from "react-router-dom";
import { apiClient } from "../apiCall";
import Pagination from 'react-bootstrap/Pagination';
import HeaderMenu from "./HeaderMenu";
import FileInfo from "./FileInfo";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

export const productsDefaultState = {
	
  response:null
};
export default function Categories(props) {
	const { state, dispatch } = useStore();

	const { categories,settings } = state;


  const [productsState, setproductsState] = useState({
		...productsDefaultState
		
    });
    const history = useHistory();
    const apiCall = apiClient(state, dispatch);		

  
	

	 

     console.log("cAtAS",categories);

     const getLang=(item,key)=>{
        console.log(key+"_"+settings.lang);
        return item[key+"_"+settings.lang];
      };

const getRows=(data,depth=0)=>{

   
    return data.map((category) =>

    <tbody><tr key={category.id}>
         <td style={{paddingLeft:15+depth*15}}><strong>{getLang(category,"name")} </strong></td>
       
         </tr>
         <tr>
         <Table size="sm">

         {getRows(category.childs,depth+1)}
         </Table>
         </tr>
        
     </tbody>);
         
};

const buttons=<div><Button variant="primary">Lisää kategoria</Button></div>;


    const rows=getRows(categories.data,0);




        return (<div className="products">
        
        <HeaderMenu title="Kategoriat" buttons={buttons}/>

    <div className="content">
<Table striped bordered hover size="sm">

        {rows}

        </Table>
        </div>
    </div>

    
   );

  
     
   
    
    
   
}