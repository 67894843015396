import {React,useState,useEffect}  from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link,useHistory } from "react-router-dom";
import { apiClient } from "../apiCall";
import Pagination from 'react-bootstrap/Pagination';

import FileInfo from "./FileInfo";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import HeaderMenu from "./HeaderMenu";

export const productsDefaultState = {
	
  response:null
};
export default function Products(props) {
	const { state, dispatch } = useStore();

	const { categories,manufacturers,common,settings } = state;


  const [productsState, setproductsState] = useState({
		...productsDefaultState
		
    });
    const history = useHistory();
    const apiCall = apiClient(state, dispatch);		

    useEffect(() => {

console.log("settingssettingssettingssettings",settings);
		///let data={...WageData};

		apiCall.GET_RAW("products/?page="+(props.match.params.page?props.match.params.page:1), function(status,err_msgs=null,response=null){


            if(status==400){
		
			
			}else if(status==200){
				
                console.log(response);
                setproductsState({...productsState,response:{...response.data}});
				//setData({...response.data,loading:false,simple:"yhteenveto"});
			}




        });
		/*if(props.id!==null){
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

		
			if(status==400){
		
			
			}else if(status==200){
				

			
				setData({...response.data,loading:false,simple:"yhteenveto"});
			}




		});

		}else{
			console.log("PROPS.data",props.data)
			setData({...props.data,loading:false,simple:props.simple});
			
		}*/
	

	  }, [props.match.params.page]);

     

    /*     if(props.match.params.folderId==undefined){
 const comp=companies.companies.map((company) =>
        
            

        <div className="item folder" key={("c"+company.id)} ><div className="inner">
          <Link to={("/company/"+company.id)}>
           <div className="image-holder"><div className="image"><img src={config.apiUrl+"/images/icons/folder"} /></div></div>  
           <div className="text"><h3>{company.name}</h3></div></Link></div></div>
   );
        return (<div className="folder-browswer">
        <div className="content"><div className="folder-header"><ul></ul></div>{comp}</div>
       
    </div>);
      }
*/
const getManuFacturer=(product)=>{

    if(product.manufacturer){
        
        return product.manufacturer.name;

    }
    return "-";

};
const getCategories=(product)=>{

    return product.categories.map((category) =>
    <span key={category.id}>
        {getLang(category,"name")}
    </span>
         
);

};
console.log("manufacturersmanufacturersmanufacturers",manufacturers);
if(!productsState.response){
    return (<div className="products">Ladataan</div>);
  }
  const getLang=(item,key)=>{
    console.log(key+"_"+settings.lang);
    return item[key+"_"+settings.lang];
  };
const wrapLink=(text,id)=>{

    return <Link to={"/tuote/"+id}>{text}</Link>
};
const rows=productsState.response.data.map((product) =>
       <tr key={product.id}>
        <td>{wrapLink(product.product_code,product.id)}</td>
        <td>{wrapLink(getLang(product,"name"),product.id)}</td>
        <td>{product.price}</td>
        <td>{(product.show_price==1?"kyllä":"ei")}</td>
        <td>{getManuFacturer(product)}</td>
        <td>{getCategories(product)}</td>
       
        </tr>
            
);


const goToPage = (pageNumber) => {
    history.push(`/tuotteet/${pageNumber}`)
 }
let page_items=[];
    let active=productsState.response.current_page;
for (let number =1; number <=  productsState.response.last_page; number++) {
    page_items.push(
      <Pagination.Item key={number} onClick={() => goToPage(number)}
      active={number === active}>
      {number}
      </Pagination.Item>,
    );
  }
  const buttons=<div><Button variant="primary">Lisää tuote</Button></div>;


        return (<div className="products">
               <HeaderMenu title="Tuotteet" buttons={buttons}/>
  <div className="content">


<Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Tuotekoodi</th>
          <th>Tuote</th>
          <th>Hinta</th>
          <th>Näytetäänkö hinta</th>
          <th>Valmistaja</th>
          <th>Kategoriat</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
    <div>
    <Pagination>{page_items}</Pagination>
    </div></div>
        </div>);

  
     
   
    
    
   
}