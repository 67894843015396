const defaultState = {
	loading:false,
	items: null,
	adding:null
	
};

export const usersInitialState = (() => {
	
	return defaultState;
})();

export const usersReducer = (state = usersInitialState, action) => {
	let users = state;
	switch (action.type) {
		case "USERS_EDIT":

		

		break;
		case "USERS_ADDING":
		
			users = {
				...state,
				
				
				adding:action.file,
				
			};

			


		break;
		case "USERS_LOADING":
		
            users = {
			...state,
			
			loading: true,
			items:null,
			
		};


		break;
		case "USERS_LOADED":

		

			users = {
				...state,
				
				loading: false,
				items:action.payload.data,
				adding:null
			};

			
		break;

		default:
			users = state;
		break;
    }
    return users;
};