export const config = {
	isDev: process.env.NODE_ENV !== "production",
	// isDev: false,
	apiUrl://https://api.duellpankki.fi/public/api
		process.env.NODE_ENV === "production"
			? "https://rautaadmin.web03.jpmedia.fi/public/api"
			: "https://rautaadmin.web03.jpmedia.fi/public/api"

	
};
