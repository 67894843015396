import axios from "axios";
import { config } from "./config";

const axiosConf = {};

export const axiosClient = axios.create(axiosConf);

export const apiClient = (state, dispatch) => {
	const token =state && state.user && state.user.token ? state.user.token : false;

	axiosClient.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			if (
				error.response &&
				(error.response.status == 403 || error.response.status == 401)
			) {
				console.log("AUTH ERROR!", error, error.request, error.response);
				dispatch({ type: "LOGOUT" });
			}

			return Promise.reject(error);
		}
	);

	return {
		

		UPLOAD: (endpoint, formData,func=null) => {
			/*const formData = new FormData();
			formData.append("file", data);
			*/
			alert("UPLOAD?");
			return axiosClient.post(config.apiUrl + "/" + endpoint, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: token,
				},
			}).then((response) => {
				
				if (func) {
					func(200,null,response);
				}
				
				return response;
			})
			.catch((error) => {

					console.log("VIRHE TÄÄLLÄ",error);

					/*if (error.response.status==400) {
                        func(error.response.status,error.response.data);

					}else{*/
					func(error.response.status,error,error.response.data);
				
			});



		},
		GET: (endpoint) => {
			let key = endpoint.toUpperCase();

			dispatch({ type: key + "_LOADING" });

			return axiosClient({
                method: "get",
                
				headers: { Authorization: "Bearer "+token },
				url: config.apiUrl + "/" + endpoint,
			})
				.then((response) => {
                    dispatch({ type: key+"_LOADED", payload: response });
                    
                    
					return response;
				})
				.catch((error) => {

					dispatch({ type: "LOGOUT" });

					Promise.reject(error);
				});
		},
		GET_RAW: (endpoint,func=null) => {
			let key = endpoint.toUpperCase();

			
			return axiosClient({
                method: "get",
                headers: { Authorization: "Bearer "+token },
				url: config.apiUrl + "/" + endpoint,
			})
				.then((response) => {
                  
                    
                    if (func) {
						func(200,null,response);
					}
					return response;
				})
				.catch((error) => {
					if (error.response.status==400) {
                        func(error.response.status,error.response.data);
                    }else{
                        Promise.reject(error);  
                    }
				});
		},
		ADD: (endpoint, data, func = null) => {
			let key = endpoint.toUpperCase() + "_ADD";
			return axiosClient({
				method: "post",
				headers: { Authorization: "Bearer "+token },
				url: config.apiUrl + "/" + endpoint,
				data: data,
			})
				.then((response) => {

                    console.log("WHAT",response);
					//dispatch({ type: key, payload: response.data });
					if (func) {
						func(200,null,response);
					}
					return response;
				})
				.catch((error) => {

                   
                    if (error.response.status==400) {
                        func(error.response.status,error.response.data);
                    }else{
                        Promise.reject(error);  
                    }
					//Promise.reject(error);
				});
		},
		EDIT: (endpoint, id, data, func = null) => {
			let key = endpoint.toUpperCase() + "_EDIT";
			//

			console.log(key,config.apiUrl + "/" + endpoint + "/" + id,token);
			return axiosClient({
				method: "post",
				headers: { Authorization: "Bearer "+token },

				url: config.apiUrl + "/" + endpoint + "/" + id,
				data
			})
				.then((response) => {
					dispatch({ type: key, payload: response.data });
					if (func) {
						func(200,null,response);
					}
					return response;
				})
				.catch((error) => {
					if (error.response.status==400) {
                        func(error.response.status,error.response.data);
                    }else{
                        Promise.reject(error);  
                    }
				});
		},
		DELETE: (endpoint, id) => {
			let key = endpoint.toUpperCase() + "_DELETE";

			return axios
				.delete(config.apiUrl + "/" + endpoint + "/" + id, {
					headers: {
						Authorization: token,
					},
				})
				.then((response) => {
					dispatch({ type: key, payload: response.data.id });
					return response;
				})
				.catch((error) => {
					Promise.reject(error);
				});
		},
	};
};