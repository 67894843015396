import {React,useEffect,useState} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
export const DefaultFormState = {
	
	forgot:false,
	
	sending:false
  };

export default function LoginForm() {
	const { state, dispatch } = useStore();

	const { user } = state;



	const [FormState, setFormState] = useState({
		...DefaultFormState
		
    });

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};

	const tryLogin = () => {

		
		let bodyFormData = new FormData();
		if(FormState.forgot){
			if(FormState.sending){
				return false;
			}

			bodyFormData.set("email", user.email);
			
	
			setFormState({...FormState,sending:true});
	
			axios({
				url: config.apiUrl + "/forgot",
				method: "post",
				data: bodyFormData
				
			}).then((response) => {
	
				console.log(response);
					if (response.status === 200 ) {
					//	dispatch({ type: "LOGIN_SUCCESS", payload: response });
					} else {
						//dispatch({ type: "LOGIN_FAIL", payload: response });
					}

					setFormState({...DefaultFormState});


				})
				.catch((Error) => {

					alert("Tarkista sähköpostiosoite.");
					setFormState({...FormState,sending:false});
					//dispatch({ type: "LOGIN_FAIL" });
				});


				return;


		}
		bodyFormData.set("email", user.email);
		bodyFormData.set("password", user.password);

		dispatch({ type: "LOGIN" });

		axios({
			url: config.apiUrl + "/auth/login",
			method: "post",
			data: bodyFormData
			
		}).then((response) => {

            console.log(response);
				if (response.status === 200 && response.data.access_token) {
					dispatch({ type: "LOGIN_SUCCESS", payload: response });
				} else {
					dispatch({ type: "LOGIN_FAIL", payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: "LOGIN_FAIL" });
			});
	};

	const createInput = (label, name) => {
		return (
			<label className="label-input">
				{label}
				<input
					type="text"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};
	const changeFormState=()=>{

		
		if(FormState.sending){
			return false;
		}
		setFormState({...FormState,forgot:!FormState.forgot});
	}
	const createPwd = (label, name) => {
		return (
			<label className="label-password">
				{label}
				<input
					type="password"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};

	return (
		<div className="loginContainer">
			<div className="loginForm">
				

				<form
					className="logform"
				>
					{createInput("Email", "email")}
					{(!FormState.forgot?createPwd("Salasana", "password"):null)}

					{!user.loading && (
						<input
							type="submit"
							className="button primary"
							name="login"
							value={FormState.forgot?"Resetoi salasana":"Kirjaudu"} 
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								tryLogin();
							}}
						/>
					)}

					<span onClick={changeFormState} className="unohtuiko">{!FormState.forgot?"Unohtuiko salasana?":"Peruuta"}</span>
					{(FormState.forgot?<p className="infoa">Salasana toimitetaan sähköpostiosoitteeseen</p>:null)}

				</form>
			</div>
		</div>
	);
}
