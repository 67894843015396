const defaultState = {
	
	openfile: null
	
};

export const commonInitialState = (() => {
	
	return defaultState;
})();

export const commonReducer = (state = commonInitialState, action) => {
	let common = state;
	switch (action.type) {
		
		case "OPENFILEINFO":
		
        console.log("OK OPEN");
            common = {
				...state,
				
				openfile: action.file
			};

		break;
		case "CLOSEFILEINFO":


			common = {
				...state,
				
				openfile: null
			};

			
		break;

		default:
			common = state;
		break;
    }
    return common;
};