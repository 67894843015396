
const localStorageKey = "settings";




const defaultState = {
	lang:"fi"
};

export const settingsInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
		? JSON.parse(window.localStorage.getItem(localStorageKey))
		: defaultState;
		
	return state;
})();

export const settingsReducer = (state = settingsInitialState, action) => {
	let user = state;
	switch (action.type) {
		
		case "LOGOUT":
			/*user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
				role:0
			};
			window.localStorage.setItem(localStorageKey, JSON.stringify(user));
			window.location = "/";
            */
			break;

		default:
			user = state;
		break;
    }
	let copy={...user};
	
	window.localStorage.setItem(localStorageKey, JSON.stringify(copy));

    return user;
};