import {React,useState,useEffect}  from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link,useHistory } from "react-router-dom";
import { apiClient } from "../apiCall";
import Pagination from 'react-bootstrap/Pagination';

import FileInfo from "./FileInfo";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import HeaderMenu from "./HeaderMenu";
import FormGroup from 'react-bootstrap/FormGroup';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UploadFile from "./UploadFile";

export const productDefaultState = {
	
  response:null
};
export default function Product(props) {
	const { state, dispatch } = useStore();

	const { categories,manufacturers,common,settings } = state;


  const [productState, setproductState] = useState({
		...productDefaultState
		
    });
   
    const apiCall = apiClient(state, dispatch);		

    useEffect(() => {

console.log("LATAA ID:",props.match.params.id);
		///let data={...WageData};

		apiCall.GET_RAW("product/"+props.match.params.id, function(status,err_msgs=null,response=null){


            if(status==400){
		
			
			}else if(status==200){
				
                console.log(response);
                setproductState({...productState,response:{...response.data}});
				//setData({...response.data,loading:false,simple:"yhteenveto"});
			}




        });
		/*if(props.id!==null){
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

		
			if(status==400){
		
			
			}else if(status==200){
				

			
				setData({...response.data,loading:false,simple:"yhteenveto"});
			}




		});

		}else{
			console.log("PROPS.data",props.data)
			setData({...props.data,loading:false,simple:props.simple});
			
		}*/
	

	  }, [props.match.params.id]);

     

    /*     if(props.match.params.folderId==undefined){
 const comp=companies.companies.map((company) =>
        
            

        <div className="item folder" key={("c"+company.id)} ><div className="inner">
          <Link to={("/company/"+company.id)}>
           <div className="image-holder"><div className="image"><img src={config.apiUrl+"/images/icons/folder"} /></div></div>  
           <div className="text"><h3>{company.name}</h3></div></Link></div></div>
   );
        return (<div className="folder-browswer">
        <div className="content"><div className="folder-header"><ul></ul></div>{comp}</div>
       
    </div>);
      }
*/
const getManuFacturer=(product)=>{

    if(product.manufacturer){
        
        return product.manufacturer.name;

    }
    return "-";

};
const getCategories=(product)=>{

    return product.categories.map((category) =>
    <span key={category.id}>
        {getLang(category,"name")}
    </span>
         
);

};
console.log("manufacturersmanufacturersmanufacturers",manufacturers);
if(!productState.response){
    return (<div className="products">Ladataan</div>);
  }
  const getLang=(item,key)=>{
    console.log(key+"_"+settings.lang);
    return item[key+"_"+settings.lang];
  };

const changeText=(e)=>{

    
    setproductState({...productState,response:{...productState.response,[e.currentTarget.name]:e.currentTarget.value}})

};

const changePrice=(e)=>{

    let value=e.currentTarget.value;
    value=value.replace(/,/g,".");

    const allowed="1234567890.";
   console.log("V",value,value.length);
    for(let i=0;i<value.length;i++){

        let c=value.charAt(i);
        if(allowed.indexOf(c)==-1){
            return false;
        }
    
    }
    let splitted=value.split(".");
    if(splitted.length>2){
        return false;
    }
    if(splitted.length==2 && splitted[1].length>2){
        return false;
    }


   
    setproductState({...productState,response:{...productState.response,[e.currentTarget.name]:value}})

};
const getInput=(label,key)=>{

return <div> <Form.Label>{label}</Form.Label>
<Form.Control
  type="text"
  value={productState.response[key]}
  placeholder=""
  onChange={changeText}
  name={key}
  
/></div>

};
const changeBoolean=(e)=>{

    let v=(e.currentTarget.checked?1:0);
   
    setproductState({...productState,response:{...productState.response,[e.currentTarget.name]:v}})

    
}
const getCheckbox=(label,key)=>{

    return <div> <Form.Check 
    type="switch"
   
    label={label}
    name={key}
    onChange={changeBoolean}
    checked={(productState.response[key]==1?true:false)}
  /></div>

};
const getPrice=(label,key)=>{

    return <div> <Form.Label>{label}</Form.Label>
    <Form.Control
      type="text"
      value={productState.response[key]}
      placeholder=""
      onChange={changePrice}
      name={key}
      
      
    /></div>
    
    };
const getTextarea=(label,key,rows=3)=>{

    return <div> <Form.Label>{label}</Form.Label>
    <Form.Control
      as="textarea"
      value={productState.response[key]}
      placeholder=""

      onChange={changeText}
      name={key}
      rows={rows}
      
    /></div>
    
    };

    const images=productState.response.images.map((image,index) =>
        
            
    <div key={index}><img src={image.thumb} />{image.name}</div>

  );


        return (<div className="product">
               <HeaderMenu title={getLang(productState.response,"name")}/>
  <div className="content fromcontent">
    <h2>Perustiedot</h2>
  <FormGroup
         >
            <Row>
                <Col>{getInput("Nimi (fi)","name_fi")}</Col>
                <Col> {getInput("Nimi (sv)","name_sv")}</Col>
            </Row>

            <Row>
                <Col>{getTextarea("Lyhyt kuvaus (fi)","short_description_fi")}</Col>
                <Col> {getTextarea("Lyhyt kuvaus (sv)","short_description_sv")}</Col>
            </Row>
            <Row>
                <Col>{getTextarea("Kuvaus (fi)","description_fi",5)}</Col>
                <Col> {getTextarea("Kuvaus (sv)","description_sv",5)}</Col>
            </Row>

            <Row>
                <Col>{getTextarea("Kuvaus (fi)","description_fi",5)}</Col>
                <Col> {getTextarea("Kuvaus (sv)","description_sv",5)}</Col>
            </Row>
            <Row>
                <Col>{getPrice("Hinta","price")}{getCheckbox("Näytä hinta","show_price")}</Col>
                <Col> </Col>
            </Row>
          
           
            </FormGroup>

            <h2>Kategoriat</h2>

            <h2>Tuotekuvat</h2>
            <UploadFile url={"product/"+productState.response.id+"/upload"}/>
            <div>{images}</div>


    <div>
    
    </div></div>
        </div>);

  
     
   
    
    
   
}