
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useStore } from "./store/useStore";
import LoginForm from "./components/LoginForm";
import Header from "./components/Header";

import React, { useEffect, useState } from 'react';
import { apiClient } from "./apiCall";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Sidenav from './components/Sidenav';
import FolderBrowser from './components/FolderBrowser';
import AddFolder from './components/AddFolder';
import EditFolder from './components/EditFolder';
import UserManagement from './components/UserManagement';
import AddUser from './components/AddUser';
import FullBasket from './components/FullBasket';
import Button from 'react-bootstrap/Button';
import Products from './components/Products';
import Product from './components/Product';
import ImportCsv from './components/ImportCsv';

import Categories from  './components/Categories';
import FileUploader from './components/FileUploader';
import FileInfo from './components/FileInfo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Logo from'./rautanet.png';



function App() {

  const { state, dispatch } = useStore();

const apiCall = apiClient(state, dispatch);



const fetchProducts = () => {
 // dispatch({ type: "FETCH_LOAD" });

  apiCall.GET("categories");
};
const fetchManufacturers = () => {
  // dispatch({ type: "FETCH_LOAD" });
 
   apiCall.GET("manufacturers");
 };

useEffect(() => {
 if(state.user.loggedIn && state.categories.firstLoad==false){
console.log("PYYSIN", state.categories.loading);
fetchProducts();

fetchManufacturers();

 }


}, [dispatch, state]);

console.log("STATE LOGGEDIN",state.user.loggedIn);
  if (state.user.loggedIn==false){

    return (<LoginForm/>);

  }else{

    console.log("STATE C LOADING",state.categories.mainLoader);
    if (state.categories.mainLoader && state.manufacturers.mainLoader) {

      return (<div>ladataan..</div>)
      

    }




  return (
    <div className="App"><Router>
      <ThemeProvider
  breakpoints={[ 'lg', 'md', 'sm']}
  minBreakpoint="sm"
>
 
      <Router>
      <Container  fluid>
      <Row >
        <Col className='fixed'><img src={Logo} />
       
        
             <Header/>
      
        
        </Col>
        <Col className="contentarea">
        
        <Route exact path="/tuotteet/:page?"  component={Products}></Route>
        <Route exact path="/kategoriat"  component={Categories}></Route>
        <Route exact path="/tuote/:id"  component={Product}></Route>
        <Route exact path="/tuocsv"  component={ImportCsv}></Route>
        </Col>
      </Row>
   
    </Container>
       
      </Router>
      </ThemeProvider>
      </Router>
    </div>
  );
  }
}

export default App;
