import {React,useState,useRef} from "react";
import logo from '../logos/duell.svg';
import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link ,NavLink} from "react-router-dom";
import Basket from "./Basket";
import { useLocation } from 'react-router-dom';
import { apiClient } from "../apiCall";
import Table from 'react-bootstrap/Table';

export const    CsvDefaultState = {
	
    loading:false,
    errors:null,
    response:null,
    received:false,
    confirm_loading:false,
    confirmed:false
    
  };




export default function ImportCsv(props) {


	const { state, dispatch } = useStore();


  const [CsvState, setCsvState] = useState({
		...CsvDefaultState,
		
    });


	const { user } = state;
    const inputRef = useRef(null);
    const location = useLocation();  
    const vahvista=()=>{

      let url="uploadcsv/confirm";

      setCsvState({...CsvState,confirm_loading:true,confirmed:false})


        apiCall.ADD(url, {},function(status,err_msgs=null){

            if(status==400){

               // setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){
              

              setCsvState({...CsvState,confirm_loading:false,confirmed:true})


            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });



    };
    const onCsvChange=(e)=>{
        

        if( e.target.files.length>0){

          setCsvState({...CsvState,loading:true,errors:null,response:null})
        console.log(e.target.files[0]);

        const formData = new FormData();
    
          formData.append(
            "file",
            e.target.files[0]
          );
           

            apiCall.UPLOAD("uploadcsv", formData,function(status,err_msgs=null,data=null){
            
                
                if(status==200){

                
                  setCsvState({...CsvState,loading:false,received:{...data.data},errors:null})

                }
                if(status==400){
                  let errors=[];

                 if(Array.isArray(data)){

                  errors=[...data];
                 }else{



                  for(var i in data){
                    for(var x=0;x<data[i].length;x++){
                      errors.push(data[i][x]);
                    }
                  }
                }
                  setCsvState({...CsvState,loading:false,received:false,errors:[...errors]})
                }
    
              });
    
            };




    }
    const apiCall = apiClient(state, dispatch);
    const handleUpload=()=>{
        console.log("click");
        inputRef.current?.click();
    };

    const getErrors=()=>{
      if(CsvState.errors){
       const err= CsvState.errors.map((error) =>
        
            

            <div className="error">{error}</div>
        );
        return <div className="errors">{err}</div>
      }
      return null;

    };

    const getCells=(row)=>{


      return row.map((d,index) =>
         
               <td>{d}</td>
        
         );



    }
    const getInfoRow=(index)=>{
      //[{is_new: false, is_new_valmistaja: false, is_new_unit: false, is_new_main_category: false,…},…]
        const data= CsvState.received.info[index];
      console.log(data.is_new_valmistaja);
        return <td className="info-td">
          {data.is_new?<span className="new">Uusi tuote</span>:<span className="old">Tuote löytyi</span>}

          {data.image_count>0?<span className="new">Kuvia {data.image_count} kpl</span>:null}
          {data.is_new_valmistaja?<span className="new">Uusi valmistaja</span>:null}
          {data.is_new_unit?<span className="new">Uusi yksikkö</span>:null}
          {data.is_new_main_category?<span className="new">Uusi pääkategoria</span>:null}
          {data.is_new_sub_category?<span className="new">Uusi alakategoria</span>:null}
          

          </td>
    }
    const getConfirmTable=()=>{

      if(CsvState.received && CsvState.received.rows){

       let rows= CsvState.received.rows.map((row,index) =>
         
               <tr><td>{getInfoRow(index)}</td>{getCells(row)}</tr>
        
         );

        
       

        return <div><button onClick={vahvista} className="btn btn-outline-primary">
        Vahvista
      </button><Table id="csv-table" striped bordered hover size="sm"><tbody>{rows}</tbody></Table><button onClick={vahvista} className="btn btn-outline-primary">
        Vahvista
      </button></div>


      }
      return null;
     
    };

    


  
    return (
        <div className="csv-import"><div className="m-3">
         
         {(!CsvState.loading && !CsvState.received? <div><input ref={inputRef} onChange={onCsvChange} className="d-none" type="file"   data-accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"/>
          <button onClick={handleUpload} className="btn btn-outline-primary">
            VALITSE ZIP 
          </button></div>:(CsvState.loading?"ladataan":null))}

       
        </div>

        {getErrors()}
        <div id="table-holder">{getConfirmTable()}</div>
        </div>

      );
}