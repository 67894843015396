import {React,useState} from "react";
import logo from '../logos/duell.svg';
import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link ,NavLink} from "react-router-dom";
import Basket from "./Basket";
import { useLocation } from 'react-router-dom';

export const HeaderStateDefaultState = {
	
    term:null
    
  };

  export const QueryStateDefaultState = {
	
    files:null,
    term:null
    
  };


export default function Header(props) {
	const { state, dispatch } = useStore();

	const { user } = state;

    const location = useLocation();  

    return <nav><ul className="mainnav">
         <li><NavLink to={'/tuotteet/'}>Tuotteet</NavLink></li>
         <li><NavLink to={'/kategoriat/'}>Kategoriat</NavLink></li>
         <li><NavLink to={'/valmisajat/'}>Valmistajat</NavLink></li>
         <li><NavLink to={'/yksikot/'}>Yksiköt</NavLink></li>
         <li><NavLink to={'/tuocsv/'}>Tuo csv</NavLink></li>
      
      </ul></nav>
}